import React from 'react';
import PropTypes from 'prop-types';
import {
  LoginSignupLayout,
  Subtitle,
} from '@fuww/library/src/Login';
import Subtitle2 from '@fuww/library/src/Typography/SubTitle2';
import Hr from '@fuww/library/src/Elements/Hr';
import { useIntl } from 'react-intl';
import Head from '../Head';
import HomeLink from '../HomeLink';
import LinkAndAnchor from '../LinkAndAnchor';
import CookieConsentMessage from '../CookieConsent/Message';
import Disclaimer from './Disclaimer';
import messages from '../../lib/messages.mjs';
import RedirectWhenLoggedIn from './RedirectWhenLoggedIn';
import { retrieveDestination } from '../../lib/routerState';
import Routes from '../../routes.mjs';
import findRegistrar from '../../lib/findRegistrar';

const SignupHead = () => {
  const intl = useIntl();
  return (
    <Head
      title={intl.formatMessage(messages['register.title'])}
      description={
        intl.formatMessage(messages['markets.description'])
      }
    />
  );
};

export const handleUserRegistration = (
  registerUser,
  userInputs,
  locale,
) => {
  const { route, query } = retrieveDestination(
    'index',
    {},
    locale,
  );

  const {
    firstName,
    lastName,
    email,
    password,
    captchaResponse,
    chamberOfCommerceNumber,
    companyName,
    country,
    language,
    websiteUrl,
    registeredVia,
  } = userInputs;

  const redirectUrl = new URL(password ? Routes.findAndGetUrls(
    route,
    query,
  ).urls.as : Routes.findAndGetUrls(
    'set-password',
    {},
  ).urls.as, window.location.origin).toString();

  registerUser({
    variables: {
      firstName,
      lastName,
      name: firstName && lastName && `${firstName} ${lastName}`,
      email,
      password,
      chamberOfCommerceNumber,
      companyName,
      country,
      language,
      websiteUrl,
      captchaResponse,
      redirectUrl,
      registeredVia: findRegistrar(redirectUrl, registeredVia),
    },
  });
};

const Signup = ({
  infoPanel, redirectLink, children, showBreadcrumbs,
}) => {
  const intl = useIntl();

  const breadcrumbs = showBreadcrumbs ? [
    <HomeLink />,
    <LinkAndAnchor
      route="register"
    >
      {intl.formatMessage(messages.signup)}
    </LinkAndAnchor>,
  ] : [];

  return (
    <LoginSignupLayout
      breadcrumbs={breadcrumbs}
      title={intl.formatMessage(messages['register.title'])}
      disclaimer={(<Disclaimer />)}
      infoPanel={infoPanel}
      redirectLink={redirectLink}
    >
      <SignupHead />
      <CookieConsentMessage />
      {children}
      <Subtitle
        prefix={intl.formatMessage(messages['register.alreadyRegistered'])}
        anchor={(
          <LinkAndAnchor
            route="login"
            styled
          >
            <Subtitle2 tag="span">
              {intl.formatMessage(messages.login)}
            </Subtitle2>
          </LinkAndAnchor>
        )}
      />
      <Hr backgroundColor="var(--black-disabled)" margin="24px 0" />
      <RedirectWhenLoggedIn />
    </LoginSignupLayout>
  );
};

Signup.propTypes = {
  children: PropTypes.node.isRequired,
  infoPanel: PropTypes.node,
  redirectLink: PropTypes.node,
  showBreadcrumbs: PropTypes.bool,
};

Signup.defaultProps = {
  infoPanel: null,
  redirectLink: null,
  showBreadcrumbs: true,
};

export default Signup;
